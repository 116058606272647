import { merge } from 'lodash';
import { FC, useMemo } from 'react';
import { createTheme, ThemeProvider } from 'react-ui-kit-exante';

import { theme } from './index';

export const ComposedThemeProvider: FC = ({ children }) => {
  const brandingTheme = window.WORKFLOW_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
