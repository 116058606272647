import { Link } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const LinkWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
}));

export const LinkTag = styled(Link)(({ theme }) => ({
  color: theme?.color?.typo?.action,
  fontSize: '16px',
  fontWeight: 500,
  textDecoration: 'none',
}));

export const PreWrapper = styled('pre')(() => ({
  marginLeft: '16px',
  marginRight: '16px',
}));
