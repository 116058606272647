import { FC } from 'react';

import ErrorIndicatorStyles from './ErrorIndicator.module.css';

export const ErrorIndicator: FC = () => (
  <div className="container-fluid">
    <div className={ErrorIndicatorStyles.ErrorIndicator}>
      <span className={ErrorIndicatorStyles.Boom}>
        Something has gone wrong!
      </span>
      <span>Please reload a page and try again</span>
    </div>
  </div>
);
