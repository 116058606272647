import { useCallback, useEffect, useState } from 'react';
import { formatNumber } from 'react-ui-kit-exante';

import { accumMarketValueService, executionService } from 'resources';
import type {
  TAccumulationMarketValue,
  TApproveExecution,
  TExecution,
} from 'types';

interface IUseBulkActionsProps {
  refetch: () => Promise<void>;
  setOnActionLoading: (value: boolean) => void;
}

export const useBulkActions = ({
  refetch,
  setOnActionLoading,
}: IUseBulkActionsProps) => {
  const [marketValue, setMarketValue] = useState('€0');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const onApprove = (values: TExecution[]) => {
    const payload: TApproveExecution = {
      workflow_execution_ids: values.map((item) => item.execution_id),
    };
    setOnActionLoading(true);

    executionService.approveExecution(payload).finally(() => {
      refetch();
      setOnActionLoading(false);
      setSelectedRows([]);
    });
  };

  const onDecline = (values: TExecution[]) => {
    const payload: TApproveExecution = {
      workflow_execution_ids: values.map((item) => item.execution_id),
    };
    setOnActionLoading(true);

    executionService.declineExecution(payload).finally(() => {
      refetch();
      setOnActionLoading(false);
      setSelectedRows([]);
    });
  };

  const handleSelectRow = useCallback(
    (value) => {
      setSelectedRows((prev) => {
        if (Array.isArray(value)) {
          return value.map((item) => item.values.execution_id);
        }

        if (prev.includes(value.values.execution_id)) {
          return prev.filter((row) => row !== value.values.execution_id);
        }

        return [...prev, value.values.execution_id];
      });
    },
    [setSelectedRows],
  );

  useEffect(() => {
    if (selectedRows.length) {
      accumMarketValueService
        .fetchMarketValue({
          workflow_execution_ids: selectedRows,
        })
        .then((data: TAccumulationMarketValue | Record<string, unknown>) => {
          setMarketValue(`€${formatNumber(data.market_value || 0)}`);
        });
    } else {
      setMarketValue('€0');
    }
  }, [selectedRows]);

  return {
    handleSelectRow,
    marketValue,
    onApprove,
    onDecline,
    selectedRows,
    setSelectedRows,
  };
};
