import {
  IndeterminateCheckbox,
  IndeterminateCheckboxHead,
} from '../components/IndeterminateCheckboxes';

export const addExtraColumn = (bulkActions, hooks) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      id: 'selectionBulk',
      width: 65,
      minWidth: 65,
      hideInMenu: true,
      disableFilters: true,
      Header: (header) => (
        <IndeterminateCheckboxHead bulkActions={bulkActions} header={header} />
      ),
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({ title: '' })}
          bulkActions={bulkActions}
          row={row}
        />
      ),
    },
    ...allColumns,
  ]);
