import { ThemeProvider } from '@mui/material/styles';
import { isFunction, isObject, difference } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { TranslationProvider } from '../../contexts/TranslationContext';
import { LocalStorage } from '../../helpers';
import { useTheme } from '../../theme';
import { NoData } from '../NoData';

import BulkActions from './BulkActions';
import CommonValue from './CommonValue';
import { EditableCell } from './EditableCell';
import { ExportButton } from './ExportButton/ExportButton';
import {
  ContainerStyled,
  TableContentStyled,
  TableStyled,
} from './Table.styled';
import { TableActions } from './TableActions/TableActions';
import { TableBody } from './TableBody/TableBody';
import { Filters } from './TableFilters/Filters';
import { makeFiltersData } from './TableFilters/helpers';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';
import { TablePagination } from './TablePagination/TablePagination';
import { DEFAULT_PAGE_SIZE, TABLE_FIRST_PAGE } from './TablePagination/const';
import { TableInfoSkeleton, TableSkeleton } from './TableSkeleton';
import { DEFAULT_SKELETON_ROWS_COUNT } from './TableSkeleton/const';
import { TableTopPanel } from './TableTopPanel';
import { SWAP_ROWS } from './components/SwapRows/const';
import { defaultColumn, serviceColumns, translatorFallback } from './constants';
import {
  createEditableRowValuesContext,
  EditableRowIdContextProvider,
  EditableRowValuesContextProvider,
} from './contexts/EditableContext';
import {
  addExtraColumn,
  getDefaultPropGetter,
  getEmptyExpandedHook,
  getEnrichedColumns,
  prepareSelectedRow,
  sortNumeric,
  flatColumns,
  addSwapRowsColumn,
} from './helpers';
import { checkIsShowFooter } from './helpers/checkIsShowFooter';
import { filtersToArray, filtersToMap } from './helpers/toFiltersObject';
import {
  useAdaptiveTableHeight,
  useColumnsKeys,
  useSaveColumnsWidths,
} from './hooks';
import { useColumnsOrder } from './hooks/useColumnsOrder';
import { getParamsFromUrl } from './hooks/useQueryParams/heplers';
import {
  TableProps,
  TableViewParamsSchema,
  TColumnResetType,
  TExtendedColumnWithAdditionalFields,
  THandleCellUpdate,
} from './types';

const Table = <Data extends object>({
  title,
  titleSize,
  description,
  disableSortBy,
  columns: originColumns,
  data,
  handleRowClick,
  handleCellClick,
  hasPagination,
  hasFilters,
  expanded,
  serverPaginationProps,
  filteringProps,
  customPaginationComponent,
  handleCellDataEdit,
  showConfirmationModal,
  showTableInfo,
  showScrollbar,
  isLoading,
  skeletonsCount = DEFAULT_SKELETON_ROWS_COUNT,
  tableId,
  noHeader,
  singleColored,
  className,
  heightShift = 0,
  pageSize: defaultPageSize,
  pageSizes,
  displayedColumnKeys,
  onSort,
  manualSortBy,
  defaultSortBy,
  isHiddenColumnSelect,
  isFlexLayout,
  exportTableParams,
  rowActions,
  onClose,
  onAdd,
  additionalActions,
  getHeaderProps: getHeaderPropsExternal = getDefaultPropGetter,
  getColumnProps: getColumnPropsExternal = getDefaultPropGetter,
  getRowProps: getRowPropsExternal = getDefaultPropGetter,
  getCellProps: getCellPropsExternal = getDefaultPropGetter,
  getFooterProps: getFooterPropsExternal = getDefaultPropGetter,
  bulkActions,
  commonValue,
  isPinnedHeader,
  noDataHeight,
  renderRowSubComponent,
  virtualized,
  saveViewParamsAfterLeave,
  saveColumnOrder,
  customHeadComponent,
  translator = translatorFallback,
  locale,
  filtersRightPanelComponent,
  useBrandedDatePicker = true,
  copyPasteMode = false,
  swapRows = SWAP_ROWS,
  onTableCellUpdate,
}: TableProps<Data>) => {
  const [tableData, setTableData] = useState(data);
  const [tableExpanded, setTableExpanded] = useState(expanded);
  const theme = useTheme();
  const tableLSWidthKey = `${tableId}-width`;
  const isData = Boolean(tableData.length);
  const showPagination =
    (hasPagination && isLoading) || (isData && hasPagination);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [localRows, setLocalRows] = useState<any>([]);
  const containerRef = useRef<HTMLInputElement>(null);

  const { refTableContent, heightTable } = useAdaptiveTableHeight(
    isPinnedHeader,
    virtualized,
  );

  const handleCellUpdate: THandleCellUpdate<Data> = useCallback(
    (values) => {
      const updatedData = [...tableData];

      if (
        Object.prototype.hasOwnProperty.call(updatedData[0], 'subRows') &&
        onTableCellUpdate &&
        typeof onTableCellUpdate === 'function'
      ) {
        onTableCellUpdate(values);
        return;
      }

      values.forEach(({ index, id, value }) => {
        updatedData[index][id] = value;
      });
      setTableData(updatedData);
    },
    [onTableCellUpdate, tableData],
  );

  const onSwapRows = useCallback((updatedTableData: Data[]) => {
    setTableData(updatedTableData);
  }, []);

  const top = useMemo(() => {
    if (!containerRef.current || !isPinnedHeader) {
      return 0;
    }

    const rect = containerRef.current?.getBoundingClientRect();

    return rect.top + heightShift;
  }, [containerRef.current, isPinnedHeader, heightShift]);

  const storage = useMemo(() => {
    const storageKey = `${tableId}-view-params`;
    return new LocalStorage<TableViewParamsSchema>(storageKey);
  }, [tableId]);

  const tableColumns = useMemo(() => {
    return flatColumns<Data>(originColumns);
  }, [originColumns]);

  const columns = useMemo(
    () =>
      getEnrichedColumns<Data>({
        tableColumns: originColumns,
        tableLSWidthKey,
        rowActions,
      }),
    [tableColumns, tableLSWidthKey, rowActions],
  );

  const { savedSorting, savedPageIndex, savedPageSize } = useMemo(() => {
    return {
      savedSorting: storage.get('sorting'),
      savedPageIndex: storage.get('page'),
      savedPageSize: storage.get('pageSize'),
    };
  }, [storage]);

  const shouldSaveViewParams = Boolean(saveViewParamsAfterLeave && tableId);

  const syncWithStorage = useCallback(
    <Key extends keyof TableViewParamsSchema>(
      key: Key,
      value: TableViewParamsSchema[Key],
    ) => {
      if (shouldSaveViewParams) {
        storage.set(key, value);
      }
    },
    [shouldSaveViewParams, storage],
  );

  const getSyncedPageIndex = () => {
    const shouldUseSavedPageIndex = saveViewParamsAfterLeave && savedPageIndex;

    return shouldUseSavedPageIndex ? savedPageIndex : TABLE_FIRST_PAGE;
  };

  const getSyncedPageSize = () => {
    const shouldUseSavedPageSize = saveViewParamsAfterLeave && savedPageSize;

    if (hasPagination) {
      return shouldUseSavedPageSize
        ? savedPageSize
        : defaultPageSize ?? DEFAULT_PAGE_SIZE;
    }

    return tableData.length || 1;
  };

  const getSyncedSortBy = () => {
    const shouldUseSavedSortBy = saveViewParamsAfterLeave && savedSorting;

    if (shouldUseSavedSortBy) {
      return savedSorting;
    }

    return (
      defaultSortBy || [
        {
          id: 'id',
          desc: false,
        },
      ]
    );
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setTableExpanded(expanded);
  }, [expanded]);

  const {
    getTableBodyProps,
    getTableProps,
    prepareRow,
    setHiddenColumns,
    allColumns: columnsWithAllInfo,
    headerGroups,
    getCellProps,
    footerGroups,
    gotoPage,
    setPageSize,
    pageCount,
    rows,
    setFilter,
    setAllFilters,
    page,
    resetResizing,
    selectedFlatRows,
    toggleAllRowsExpanded,
    setColumnOrder,
    state: {
      columnOrder,
      pageIndex,
      pageSize,
      columnResizing,
      sortBy,
      filters: clientFilters,
      ...restState
    },
  } = useTable<Data>(
    {
      columns,
      data: tableData,
      defaultColumn: handleCellDataEdit
        ? { Cell: EditableCell }
        : defaultColumn,
      handleCellDataEdit,
      showConfirmationModal,
      disableFilters: filteringProps?.disableFilters,
      manualFilters: filteringProps?.manualFilters,
      disableSortBy: disableSortBy || isLoading,
      initialState: {
        pageIndex: getSyncedPageIndex(),
        pageSize: getSyncedPageSize(),
        sortBy: getSyncedSortBy(),
        selectedRowIds: prepareSelectedRow(bulkActions, localRows),
        expanded:
          isObject(tableExpanded) &&
          isObject(tableExpanded.listOfInitialExpandedRowKeys)
            ? tableExpanded.listOfInitialExpandedRowKeys
            : {},
      },
      manualSortBy,
      sortTypes: {
        numeric: sortNumeric,
      },
    },
    useFilters,
    useSortBy,
    expanded ? useExpanded : getEmptyExpandedHook,
    usePagination,
    isFlexLayout ? useFlexLayout : useBlockLayout,
    useResizeColumns,
    useRowSelect,
    useColumnOrder,
    (hooks) => bulkActions && addExtraColumn(bulkActions, hooks),
    (hooks) => swapRows.show && addSwapRowsColumn(hooks, swapRows, onSwapRows),
  );
  // This array contains fields from columns, including custom fields
  const allColumns =
    columnsWithAllInfo as TExtendedColumnWithAdditionalFields<Data>[];

  const nonServiceColumns = useMemo(
    () => allColumns.filter(({ id }) => !serviceColumns.has(id)),
    [allColumns],
  );

  // here we can group params to pagination, sorting, filters.
  const queryParams = useMemo(() => getParamsFromUrl(), []);
  const total = serverPaginationProps?.total || tableData.length;
  const paginationProps = useMemo(() => {
    return (
      serverPaginationProps || {
        total,
        pageIndex,
        pageCount,
        pageSize,
        setPage: gotoPage,
        setPageSize,
      }
    );
  }, [
    gotoPage,
    pageCount,
    pageIndex,
    pageSize,
    serverPaginationProps,
    setPageSize,
    total,
  ]);

  const getValidatedPageIndex = (index: number, count: number) => {
    const isValidPageIndex = index <= count;
    const lastPageIndex = count;
    const result = isValidPageIndex ? index : lastPageIndex - 1;

    return result < 0 ? 0 : result;
  };

  const {
    pageIndex: index,
    pageSize: size,
    pageCount: count,
  } = paginationProps;
  const startLineIndex = getValidatedPageIndex(index, count) * size;
  const endLine =
    startLineIndex + (serverPaginationProps ? rows.length : page.length);

  useEffect(() => {
    createEditableRowValuesContext<Data>();
  }, []);

  useEffect(() => {
    if (bulkActions && difference(rows, localRows).length !== 0) {
      setLocalRows(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (onSort) {
      syncWithStorage('sorting', sortBy);
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  useEffect(() => {
    const dataLength = tableData.length;
    if (!hasPagination && dataLength && pageSize !== dataLength) {
      setPageSize(dataLength);
    }
  }, [tableData, hasPagination, pageSize, setPageSize]);

  useSaveColumnsWidths(
    tableLSWidthKey,
    columnResizing as Parameters<typeof useSaveColumnsWidths>[1],
  );
  const { resetColumnKeys, visibleColumnKeys, handleVisibleColumnKeysChange } =
    useColumnsKeys<Data>({
      resetResizing,
      displayedColumnKeys,
      tableId,
      tableLSWidthKey,
      setHiddenColumns,
      columns,
      setColumnOrder,
    });

  const showFooter = useMemo(() => {
    return checkIsShowFooter<Data>(columns, footerGroups);
  }, [columns]);

  const renderExportButton = useCallback(() => {
    return (
      <ExportButton<Data>
        title={tableId}
        tableColumns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        exportTableParams={exportTableParams}
        data={rows}
        filters={filteringProps?.filters || queryParams}
        sorting={sortBy}
        total={total}
      />
    );
  }, [
    rows,
    exportTableParams,
    visibleColumnKeys,
    tableColumns,
    tableId,
    filteringProps?.filters,
    queryParams,
    sortBy,
    total,
  ]);

  const onToggleFilterExpanded = useCallback(
    () => setFiltersExpanded((prev) => !prev),
    [],
  );

  const showHeader =
    exportTableParams ||
    onAdd ||
    hasFilters ||
    title ||
    (additionalActions && additionalActions.length);

  const hasActiveFilters = useMemo(
    () =>
      Boolean(clientFilters?.length) ||
      Object.entries(filteringProps?.filters || queryParams).some(
        ([key, value]) => visibleColumnKeys.includes(key) && Boolean(value),
      ),
    [
      clientFilters.length,
      filteringProps?.filters,
      queryParams,
      visibleColumnKeys,
    ],
  );
  const activeFilters = useMemo(
    () =>
      filteringProps
        ? filteringProps?.filters || queryParams
        : clientFilters || queryParams,
    [filteringProps, clientFilters, queryParams],
  );

  const { base, additional } = makeFiltersData(
    allColumns,
    tableData,
    filteringProps?.additionalFilters,
  );

  const baseOptions = base?.options?.length ? base.options : [];
  const additionalOptions = additional?.options?.length
    ? additional.options
    : [];
  const filtersList = () =>
    [...baseOptions, ...additionalOptions]?.map((item) => item.value) ?? [];

  const activeFiltersCount = useMemo(
    () =>
      Object.keys(activeFilters).filter((key) => {
        if (filtersList().includes(key)) {
          return activeFilters[key];
        }

        return false;
      })?.length,
    [activeFilters],
  );

  const visibleColumns = useMemo(() => {
    return columnsWithAllInfo.filter(
      (column) =>
        visibleColumnKeys.includes(String(column.id)) ||
        column.id === 'selection',
    );
  }, [visibleColumnKeys, columnsWithAllInfo]);

  const { resetToDefaultColumnOrder, order } = useColumnsOrder({
    saveColumnOrder,
    setColumnOrder,
    storage,
    visibleColumns,
    columnOrder,
    columns,
  });

  useEffect(() => {
    if (
      typeof tableExpanded === 'object' &&
      typeof tableExpanded.listOfInitialExpandedRowKeys === 'boolean' &&
      tableExpanded.listOfInitialExpandedRowKeys
    ) {
      toggleAllRowsExpanded(true);
    }
  }, [tableExpanded, toggleAllRowsExpanded]);

  const syncedSetPage: typeof paginationProps.setPage = useCallback(
    (innerPage) => {
      syncWithStorage(
        'page',
        isFunction(innerPage)
          ? innerPage(paginationProps.pageIndex)
          : innerPage,
      );

      paginationProps.setPage(innerPage);
    },
    [paginationProps, syncWithStorage],
  );

  const syncedSetLimit: typeof paginationProps.setPageSize = useCallback(
    (value) => {
      if (saveViewParamsAfterLeave) {
        const newPageSize = isFunction(value)
          ? value(paginationProps.pageSize)
          : value;
        syncWithStorage('pageSize', newPageSize);
      }

      paginationProps.setPageSize(value);
    },
    [paginationProps, saveViewParamsAfterLeave, syncWithStorage],
  );
  const syncedSetFilter: typeof setFilter = useCallback(
    (column: string, value: unknown) => {
      if (shouldSaveViewParams) {
        const savedFilters = storage.get('filters');

        const newViewParams = savedFilters || {};

        newViewParams[column] = value;

        storage.set('filters', newViewParams);
      }

      setFilter(column, value);
    },
    [setFilter, shouldSaveViewParams, storage],
  );

  const getSavedFilters = useCallback(() => {
    const savedFilters = storage.get('filters') || {};
    return filtersToMap(savedFilters);
  }, [storage]);

  const getCurrentFilters = useCallback(() => {
    const currentFilters = filteringProps?.filters || queryParams;

    if (shouldSaveViewParams) {
      return {
        ...currentFilters,
        ...getSavedFilters(),
      };
    }
    return currentFilters;
  }, [
    filteringProps?.filters,
    getSavedFilters,
    queryParams,
    shouldSaveViewParams,
  ]);

  const getNewFilters = useCallback(
    (updater: Parameters<typeof setAllFilters>[0]) => {
      const savedFilters = storage.get('filters') || {};
      const newFilters = isFunction(updater)
        ? updater(filtersToArray(getCurrentFilters()))
        : updater;
      const newFiltersMap = filtersToMap<Data>(newFilters);

      const newFiltersAsMap = {
        ...savedFilters,
        ...newFiltersMap,
      };

      const newFiltersAsArray = filtersToArray(newFiltersAsMap);

      return {
        asMap: newFiltersAsMap,
        asArray: newFiltersAsArray,
      };
    },
    [getCurrentFilters, storage],
  );

  const syncedSetAllFilters: typeof setAllFilters = useCallback(
    (updater) => {
      if (!saveViewParamsAfterLeave) {
        setAllFilters(updater);
        return;
      }

      const shouldRemoveAllFilters =
        Array.isArray(updater) && updater.length === 0;

      if (shouldRemoveAllFilters) {
        storage.remove('filters');
        setAllFilters(updater);
      } else {
        const { asMap } = getNewFilters(updater);

        storage.set('filters', asMap);
        setAllFilters(updater);
      }
    },
    [saveViewParamsAfterLeave, setAllFilters, storage, getNewFilters],
  );

  const resetColumns = (type: TColumnResetType) => {
    resetColumnKeys(type);

    if (type === 'default') {
      resetToDefaultColumnOrder();
    }
  };

  const paginationComponent = customPaginationComponent || (
    <TablePagination
      tableId={tableId}
      {...paginationProps}
      pageSizes={pageSizes}
      isLoading={isLoading}
      setPageSize={syncedSetLimit}
      setPage={syncedSetPage}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <TranslationProvider translator={translator} locale={locale}>
        <ContainerStyled
          className={className}
          {...getTableProps()}
          ref={containerRef}
          top={top}
        >
          {showHeader && (
            <TableActions
              title={title}
              titleSize={titleSize}
              description={description}
              hasFilters={hasFilters}
              filtersExpanded={filtersExpanded}
              onToggleFilterExpanded={onToggleFilterExpanded}
              exportButton={exportTableParams ? renderExportButton() : null}
              onAdd={onAdd}
              onClose={onClose}
              additionalActions={additionalActions}
              hasActiveFilters={hasActiveFilters}
              activeFiltersCount={activeFiltersCount}
            />
          )}
          {customHeadComponent && customHeadComponent}
          {hasFilters && (
            <Filters<Data>
              filtersList={filtersList}
              setFilter={syncedSetFilter}
              setAllFilters={syncedSetAllFilters}
              removeServerFilters={filteringProps?.removeAllFilters}
              additionalFilters={filteringProps?.additionalFilters}
              filters={getCurrentFilters()}
              data={tableData}
              allColumns={nonServiceColumns}
              isExpanded={filtersExpanded}
              onChangeExpanded={setFiltersExpanded}
              filtersRightPanelComponent={filtersRightPanelComponent}
              useBrandedDatePicker={useBrandedDatePicker}
              requiredFilters={filteringProps?.required}
            />
          )}
          {isLoading && showTableInfo ? (
            <TableInfoSkeleton isHiddenColumnSelect={isHiddenColumnSelect} />
          ) : (
            isData && (
              <>
                <TableTopPanel
                  showTableInfo={showTableInfo}
                  resetColumns={resetColumns}
                  allColumns={allColumns}
                  startLine={startLineIndex + 1}
                  endLine={endLine}
                  totalLines={total}
                  visibleColumnKeys={visibleColumnKeys}
                  handleVisibleColumnKeysChange={handleVisibleColumnKeysChange}
                  isHiddenColumnSelect={isHiddenColumnSelect}
                />
                <CommonValue data={commonValue} />
                {bulkActions && (
                  <BulkActions
                    data={tableData}
                    totalLines={total}
                    bulkActions={bulkActions}
                    selectedRows={selectedFlatRows}
                  />
                )}
              </>
            )
          )}
          <TableStyled
            style={heightTable}
            showScrollbar={showScrollbar}
            isPinnedHeader={isPinnedHeader}
            className="Table"
          >
            <TableContentStyled
              ref={refTableContent}
              data-test-id="table__content"
              className="TableContent"
            >
              {!noHeader && (
                <TableHead
                  columnOrder={order}
                  setColumnOrder={setColumnOrder}
                  isLoading={isLoading}
                  headerGroups={headerGroups}
                  isPinnedHeader={isPinnedHeader}
                  showRowActions={rowActions?.show}
                  getHeaderPropsExternal={getHeaderPropsExternal}
                  getColumnPropsExternal={getColumnPropsExternal}
                  storage={storage}
                  saveColumnOrder={saveColumnOrder}
                  allColumns={allColumns}
                />
              )}

              {isLoading && (
                <TableSkeleton
                  columns={visibleColumns}
                  isFlexLayout={isFlexLayout}
                  skeletonsCount={skeletonsCount}
                />
              )}

              {!isLoading &&
                (isData ? (
                  <EditableRowIdContextProvider>
                    <EditableRowValuesContextProvider>
                      <TableBody<Data>
                        allColumns={allColumns}
                        expanded={tableExpanded}
                        handleCellUpdate={handleCellUpdate}
                        rows={
                          serverPaginationProps || tableExpanded ? rows : page
                        }
                        prepareRow={prepareRow}
                        tbodyProps={getTableBodyProps()}
                        getCellProps={getCellProps}
                        handleRowClick={handleRowClick}
                        handleCellClick={handleCellClick}
                        singleColored={singleColored}
                        rowActions={rowActions}
                        data={tableData}
                        getCellPropsExternal={getCellPropsExternal}
                        getRowPropsExternal={getRowPropsExternal}
                        getColumnPropsExternal={getColumnPropsExternal}
                        renderRowSubComponent={renderRowSubComponent}
                        virtualized={virtualized}
                        locale={locale}
                        tableState={{
                          pageIndex,
                          pageSize,
                          columnResizing,
                          sortBy,
                          filters: clientFilters,
                          ...restState,
                        }}
                        copyPasteMode={copyPasteMode}
                      />
                    </EditableRowValuesContextProvider>
                  </EditableRowIdContextProvider>
                ) : (
                  <NoData height={noDataHeight} />
                ))}

              {showFooter && (
                <TableFooter
                  getFooterPropsExternal={getFooterPropsExternal}
                  footerGroups={footerGroups}
                />
              )}
            </TableContentStyled>
          </TableStyled>
          {showPagination && paginationComponent}
        </ContainerStyled>
      </TranslationProvider>
    </ThemeProvider>
  );
};

export { Table };
