import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UseTableCellProps } from 'react-table';
import { Notification, Table, useTableData } from 'react-ui-kit-exante';

import { DAY_IN_SECONDS } from 'constants/common';
import { useLogHandleTime, usePrevious } from 'hooks';
import { workflowParamsService, workflowService } from 'resources';
import type { TWorkflow } from 'types';
import { getErrorMessage, prepareTableId } from 'utils';

import { getColumns } from './columns';
import {
  DISPLAYED_COLUMN_KEYS,
  EDITABLE_WORKFLOW_LIST,
  MAX_AVAILABLE_DAYS,
  PAGE_SIZE,
  PAGE_SIZES,
  TTL_ACTION,
} from './constants';

export const WorkflowListPage = () => {
  const history = useHistory();
  const tableId = prepareTableId('workflowList');

  const { logHandleTime, setStartHandleTime } =
    useLogHandleTime('workflow-list-page');

  const getWorkflowList = useCallback(() => {
    setStartHandleTime();

    return workflowService.fetchWorkflowList({ active: true });
  }, [setStartHandleTime]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getWorkflowList },
      tableId,
    }),
    [getWorkflowList, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
  } = useTableData<TWorkflow[]>(tableDataArgs);
  const columns = useMemo(() => getColumns(), []);
  const getRowProps = () => ({
    style: {
      cursor: 'pointer',
    },
  });
  const handleCellClick = useCallback(
    ({ row: { original }, value }: UseTableCellProps<TWorkflow>) => {
      if (value) {
        history.push(`/wfe/list/${original.hash_sum}`);
      }
    },
    [history],
  );

  const handleSave = useCallback(
    async (_: TWorkflow, { ttl, wf_type: wfType }: TWorkflow) => {
      if (
        !Number.isInteger(Number(ttl)) ||
        Number(ttl) <= 0 ||
        Number(ttl) > MAX_AVAILABLE_DAYS
      ) {
        Notification.error({
          title: getErrorMessage(
            undefined,
            'The number of days should be an integer',
          ),
        });

        return false;
      }

      await workflowParamsService.updateWorkflowParams(
        {
          wf_type: wfType,
        },
        {
          ttl: Number(ttl * DAY_IN_SECONDS),
          ttl_action: TTL_ACTION,
        },
      );

      return refetch();
    },
    [refetch],
  );

  const rowActions = useMemo(
    () => ({
      isEditedRow: (values: any) => {
        if (EDITABLE_WORKFLOW_LIST.includes(values.wf_type)) {
          return true;
        }

        return false;
      },
      show: true,
      onSave: handleSave,
    }),
    [handleSave],
  );

  const previousWorkflowList = usePrevious(data);

  useEffect(() => {
    if (data && !isEqual(previousWorkflowList, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousWorkflowList]);

  return (
    <Table
      columns={columns}
      data={data || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      rowActions={rowActions}
      saveColumnOrder
      showTableInfo
      tableId={tableId}
      title="List"
    />
  );
};
