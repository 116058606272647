import {
  TDefaultTheme,
  blockNonNativeProps,
  styled,
} from 'react-ui-kit-exante';

type TtlEditableProps = {
  editable: boolean;
  theme?: TDefaultTheme;
};

export const TtlText = styled('span', {
  shouldForwardProp: blockNonNativeProps(['editable']),
})<TtlEditableProps>(({ editable, theme }) => ({
  color: editable ? 'inherit' : theme.color.typo.secondary,
}));

export const IconRight = styled('span')(() => ({
  padding: '8px',
}));
