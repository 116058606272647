import { styled, TDefaultTheme } from 'react-ui-kit-exante';

type WorkflowNotFoundProps = {
  theme: TDefaultTheme;
};

export const WorkflowNotFound = styled('div')(
  ({ theme }: WorkflowNotFoundProps) => ({
    color: theme?.color?.typo?.radical,
    display: 'flex',
  }),
);
