import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UseTableCellProps } from 'react-table';
import { Table, useTableData } from 'react-ui-kit-exante';

import {
  DEFAULT_POOLING_INTERVAL_DATA_20,
  EMPTY_ARRAY,
} from 'constants/common';
import { useInterval, useLogHandleTime, usePrevious } from 'hooks';
import { executionService } from 'resources';
import {
  clearSelectedExecutionAction,
  selectedExecutionAction,
} from 'store/executions';
import { selectWorkflow } from 'store/workflow';
import type { IExecutionData, TExecution } from 'types';
import {
  calculateCountOfPages,
  getThreeDaysDefaultFilter,
  prepareTableId,
} from 'utils';

import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { getAdditionalFilters } from './filters';

export const ExecutionsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableId = prepareTableId('executions');
  const { data: workflowOptions } = useSelector(selectWorkflow);

  const { logHandleTime, setStartHandleTime } =
    useLogHandleTime('executions-page');

  const getExecutions = useCallback(
    ({ params: { skip, ...rest } }) => {
      setStartHandleTime();

      return executionService.fetchExecutions({
        ...rest,
        offset: skip,
      });
    },
    [setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getExecutions },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getExecutions, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    page,
    setPage,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData: refetch,
  } = useTableData<IExecutionData>(tableDataArgs);

  const isInitialLoading = !data && isLoading;

  useEffect(() => {
    dispatch(clearSelectedExecutionAction());
  }, []);

  useInterval(() => refetch(), DEFAULT_POOLING_INTERVAL_DATA_20);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        workflowOptions,
      }),
    [setFilter, removeFilter, workflowOptions],
  );
  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getThreeDaysDefaultFilter(),
      }),
    [filters, removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const getRowProps = () => ({
    style: {
      cursor: 'pointer',
    },
  });

  const handleCellClick = useCallback(
    ({ row: { original }, value }: UseTableCellProps<TExecution>) => {
      if (value) {
        dispatch(selectedExecutionAction(original));

        history.push(`/wfe/executions/${original.execution_id}`, {
          locationInfo: !!original.info?.length,
          locationWfType: original.wf_type,
          locationWorkflow: original.workflow,
        });
      }
    },
    [dispatch, history],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const previousExecutions = usePrevious(data?.results);

  useEffect(() => {
    if (data?.results && !isEqual(previousExecutions, data.results)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousExecutions]);

  return (
    <Table
      columns={columns}
      data={data?.results || EMPTY_ARRAY}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isInitialLoading}
      isPinnedHeader
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      showScrollbar
      tableId={tableId}
      title="Executions"
    />
  );
};
